// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels")

// require trix text editor and action text
require("trix");
require("@rails/actiontext");

// import the application.scss we created for the bootstrap CSS (if you are not using assets stylesheet)
import "../stylesheets/application";

// import the bootstrap javascript module
import "bootstrap";

// Initialize tooltip and popover for Bootstrap
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
});

// include jQuery in global and window scope (so we can access it globally)
// in your web browser, when we type $('.div'), it is actually refering to global.$('.div')
var jQuery = require('jquery');
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
// require("../vendor/javascripts/jquery-ui/jquery-ui.min")

// include moment in global and window scope (so we can access it globally)
// var moment = require('moment')
// global.moment = moment;
// window.moment = moment;

// Import components for template
// require("@nathanvda/cocoon")
require("../vendor/javascripts/pace/pace.min");
require("../vendor/javascripts/peity/jquery.peity.min");
require("../vendor/javascripts/slimscroll/jquery.slimscroll.min");
require("../vendor/javascripts/metisMenu/jquery.metisMenu");
// import "daterangepicker";
// import "select2";
// require("../vendor/javascripts/iCheck/icheck.min")
import "./inspinia";
// require("../vendor/javascripts/wow/wow.min")

// Uncomment to copy all static images under ../images and svg under ../svg to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// const svgs = require.context('../svg', true);
// const svgPath = (name) => svgs(name, true);